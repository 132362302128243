import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  LinearProgress,
  Link,
  TextareaAutosize,
} from '@mui/material';
import { ls } from '../../i18n/translations';
import SteielTextInput from '../ui/SteielTextInput';
import SteielButton from '../ui/SteielButton';
import ErrorMessage from '../ErrorMessage';
import { Form, Formik } from 'formik';
import {
  PASSWORD_MIN_LENGTH,
  validateEmail,
  validatePassword,
  validateRequiredField,
} from '../../helpers/validators';
import {
  capitalize,
  catchErrors,
  getErrorMessage,
  getLanguageCode,
  getLanguageFromCode,
  isObjEmpty,
  log,
  logErr,
  replaceAll,
  uniqueArr,
  uploadLogo,
} from '../../helpers/utils';
import { useNavigate } from 'react-router-dom';
import { Body1, Body2 } from '../ui/Typography';
import { useMQ } from '../../hook/useMQ';
import { colors } from '../../theme';
import Flex from '../ui/Flex';
import SteielSelect from '../ui/SteielSelect';
import { useUser } from '../../hook/useUser';
import { useSearchParams } from '../../hook/useSearchParams';
import { useMount, useSetState } from 'react-use';
import Center from '../ui/Center';
import sdkClient from '../../graphql/sdkClient';
import { useUsers } from '../../hook/useUsers';
import { PageName } from '../../routes';
import { Action, Company, LanguageCode, UserRole } from '../../graphql/codegen/graphql-react';
import SteielDialog from '../ui/SteielDialog';
import { useAuth } from '../../hook/useAuth';
import SteielAutocomplete from '../ui/SteielAutocomplete';
import config from '../../config';
import { Close as CloseIcon } from '@mui/icons-material';
import { useDarkMode } from '../../hook/useDarkMode';

interface FormData {
  id: string;
  name: string;
  surname: string;
  email: string;
  password: string;
  repeatPassword: string;
  role: string;
  notes: string;
  company: string;
  language: string;
  place: string;
  companyDomain: string;
}

interface EditUserFormProps {
  isNewUser?: boolean;
}

const EditUserForm: React.FC<EditUserFormProps> = ({ isNewUser }) => {
  const [errorFromServer, setErrorFromServer] = React.useState('');
  const navigate = useNavigate();
  const { isMobileOrTablet } = useMQ();
  const { userIdParam } = useSearchParams();
  const { user, userError, userLoading, userRefetch } = useUser(userIdParam);
  const { usersError, usersLoading, usersRefetch } = useUsers();
  const [state, setState] = useSetState<{
    loadingData: boolean;
    companiesOptions: string[];
    showSuccessDialog: boolean;
    showDeleteUserDialog: boolean;
    deletingUser: boolean;
    logoFile: File | null;
    logoInputKey: number;
  }>({
    loadingData: true,
    companiesOptions: [],
    showSuccessDialog: false,
    showDeleteUserDialog: false,
    deletingUser: false,
    logoFile: null,
    logoInputKey: Date.now(),
  });
  const auth = useAuth();
  const { isDarkMode } = useDarkMode();

  useMount(async () => {
    try {
      setState({ loadingData: true });
      if (!auth.isAdmin) {
        return;
      }
      const { companies } = await sdkClient().companies();
      setState({
        companiesOptions: uniqueArr(
          companies
            ?.map((c) => {
              return c?.name || '';
            })
            .filter((el) => el) || [],
        ),
      });
    } catch (error) {
      logErr('error loading data', error);
      catchErrors(error, setErrorFromServer);
    } finally {
      setState({ loadingData: false });
    }
  });

  if (state.loadingData || state.deletingUser || (!isNewUser && userLoading) || usersLoading) {
    return (
      <Center mt={1}>
        <CircularProgress />
      </Center>
    );
  }

  const initialFormData: FormData = {
    id: user.id || '',
    name: user.name || '',
    company: isNewUser
      ? auth.isAdmin
        ? ''
        : auth.user?.Company?.name || ''
      : user.Company?.name || '',
    companyDomain: '',
    email: user.email || '',
    notes: user.notes || '',
    password: '',
    repeatPassword: '',
    surname: user.surname || '',
    role: capitalize(
      // @ts-ignore
      ls[(user.roles?.[0] || UserRole.Viewer).toLowerCase()],
    ),
    language: getLanguageFromCode(user?.languageCode || auth.user?.languageCode || LanguageCode.En),
    place: user.place || '',
  };

  let roleAllowedValues: string[] = [
    // @ts-ignore
    capitalize(ls[UserRole.Viewer.toLowerCase()]),
    // @ts-ignore
    capitalize(ls[UserRole.Technician.toLowerCase()]),
  ];
  if (auth.isAdmin || user.roles?.includes(UserRole.Supertechnician)) {
    roleAllowedValues = [
      ...roleAllowedValues,
      // @ts-ignore
      capitalize(ls[UserRole.Supertechnician.toLowerCase()]),
    ];
  }
  if (auth.isAdmin || user.roles?.includes(UserRole.Admin)) {
    roleAllowedValues = [
      ...roleAllowedValues,
      // @ts-ignore
      capitalize(ls[UserRole.Admin.toLowerCase()]),
    ];
  }
  if (auth.isTechnician) {
    roleAllowedValues = [
      // @ts-ignore
      capitalize(ls[UserRole.Viewer.toLowerCase()]),
    ];
  }

  return (
    <Box>
      <ErrorMessage
        containerStyle={{ paddingBottom: 2, textAlign: 'center' }}
        message={!isNewUser ? getErrorMessage(userError) : ''}
      />
      <ErrorMessage
        containerStyle={{ paddingBottom: 2, textAlign: 'center' }}
        message={getErrorMessage(usersError)}
      />
      <Formik
        initialValues={initialFormData}
        validate={(values) => {
          const errors: Partial<FormData> = {};
          const name = validateRequiredField(values.name);
          const email = validateEmail(values.email);
          const password = validatePassword(values.password);
          if (!name.isValid) {
            errors.name = ls.getString(name.errorTranslationKey);
          }
          if (!email.isValid) {
            errors.email = ls.getString(email.errorTranslationKey);
          }
          if (isNewUser && !password.isValid) {
            errors.password = ls
              .getString(password.errorTranslationKey)
              .replace('{0}', `${PASSWORD_MIN_LENGTH}`);
          }
          if (isNewUser && values.password !== values.repeatPassword) {
            errors.password = ls.passwordMismatch;
          }
          // reset errorFromServer
          if (!isObjEmpty(errors)) {
            setErrorFromServer('');
          }
          return errors;
        }}
        onSubmit={async (values, actions) => {
          try {
            log('submitting', values);
            setErrorFromServer('');
            // check input
            if (!values.company) {
              throw new Error(capitalize(ls.invalidCompanyValue));
            }
            let company: Company | null = null;
            if (
              !isNewUser ||
              (isNewUser && capitalize(values.role) !== capitalize(ls.superTechnician))
            ) {
              const { existCompany } = await sdkClient().existCompany({
                input: { companyName: values.company },
              });
              company = existCompany as Company;
              if (!company) {
                throw new Error(capitalize(ls.invalidCompanyValue));
              }
            }
            const userRoles: UserRole[] = [];
            switch (capitalize(values.role)) {
              case capitalize(ls.viewer):
                userRoles.push(UserRole.Viewer);
                break;
              case capitalize(ls.technician):
                userRoles.push(UserRole.Technician);
                break;
              case capitalize(ls.superTechnician):
                userRoles.push(UserRole.Supertechnician);
                break;
              case capitalize(ls.admin):
                userRoles.push(UserRole.Admin);
                break;
              default:
                userRoles.push(UserRole.Viewer);
                break;
            }
            const companyDomainParsed = replaceAll(
              replaceAll(values.companyDomain.toLowerCase(), ' ', '-'),
              '&',
              '-',
            );
            const { manageUser } = await sdkClient().manageUser({
              input: {
                action: isNewUser ? Action.Create : Action.Update,
                companyId: company?.id,
                companyName: values.company,
                email: values.email,
                languageCode: getLanguageCode(values.language),
                name: values.name,
                notes: values.notes,
                password: isNewUser ? values.password : undefined,
                place: values.place,
                surname: values.surname,
                userId: isNewUser ? undefined : user.id,
                roles: userRoles,
                companyDomain: companyDomainParsed,
              },
            });
            if (!isNewUser) {
              await userRefetch();
            }
            await usersRefetch();
            // upload logo
            if (state.logoFile && manageUser?.companyId) {
              const res = await uploadLogo(manageUser!.companyId, state.logoFile);
              if (!res.result) {
                setErrorFromServer(res.err || '');
                return;
              }
            }
            setState({ showSuccessDialog: true, logoFile: null, logoInputKey: Date.now() });
          } catch (error) {
            logErr('error on submit edit user form', error);
            catchErrors(error, setErrorFromServer);
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {(props) => {
          const nameError = (props.dirty && props.touched.name && props.errors.name) || '';
          const emailError = (props.dirty && props.touched.email && props.errors.email) || '';
          const passwordError =
            (props.dirty && props.touched.password && props.errors.password) || '';

          const firstError = emailError || passwordError || errorFromServer || '';

          const labelWidth = isMobileOrTablet ? undefined : 180;
          const textFieldWidth = isMobileOrTablet ? '100%' : 500;
          const rootBoxWidth = isMobileOrTablet
            ? '100%'
            : // @ts-ignore
              labelWidth + textFieldWidth;

          const companyDomainParsed = replaceAll(
            replaceAll(props.values.companyDomain.toLowerCase(), ' ', '-'),
            '&',
            '-',
          );

          const companyDomainLink = `https://${companyDomainParsed}.${config.env.frontend
            .replace(config.env.isDev ? 'http' : 'https', '')
            .replace('://', '')}`;

          return (
            <Form>
              <Box width={rootBoxWidth}>
                {!isNewUser ? (
                  <Flex justifyContent='flex-start'>
                    <SteielTextInput
                      fieldName='id'
                      formProps={props}
                      label={ls.id.toUpperCase()}
                      labelStyle={{
                        weight: 600,
                        width: labelWidth,
                      }}
                      disabled={true}
                      textFieldProps={{
                        placeholder: ls.id.toUpperCase(),
                        required: true,
                        error: false,
                        size: 'small',
                        style: {
                          backgroundColor: isDarkMode ? colors.darkBackground2 : '#fff',
                          width: textFieldWidth,
                        },
                      }}
                    />
                  </Flex>
                ) : null}
                <Flex mt={isNewUser ? 0 : 1} justifyContent='flex-start'>
                  <SteielTextInput
                    fieldName='name'
                    formProps={props}
                    label={capitalize(ls.name)}
                    labelStyle={{
                      weight: 600,
                      width: labelWidth,
                    }}
                    textFieldProps={{
                      placeholder: capitalize(ls.name),
                      required: true,
                      error: Boolean(nameError),
                      size: 'small',
                      style: {
                        backgroundColor: isDarkMode ? colors.darkBackground2 : '#fff',
                        width: textFieldWidth,
                      },
                    }}
                  />
                </Flex>
                <Flex mt={1} justifyContent='flex-start'>
                  <SteielTextInput
                    fieldName='surname'
                    formProps={props}
                    label={capitalize(ls.surname)}
                    labelStyle={{
                      weight: 600,
                      width: labelWidth,
                    }}
                    textFieldProps={{
                      placeholder: capitalize(ls.surname),
                      error: false,
                      size: 'small',
                      style: {
                        backgroundColor: isDarkMode ? colors.darkBackground2 : '#fff',
                        width: textFieldWidth,
                      },
                    }}
                  />
                </Flex>
                <Flex mt={1} justifyContent='flex-start'>
                  <SteielTextInput
                    fieldName='place'
                    formProps={props}
                    label={capitalize(ls.place)}
                    labelStyle={{
                      weight: 600,
                      width: labelWidth,
                    }}
                    textFieldProps={{
                      placeholder: capitalize(ls.place),
                      error: false,
                      size: 'small',
                      style: {
                        backgroundColor: isDarkMode ? colors.darkBackground2 : '#fff',
                        width: textFieldWidth,
                      },
                    }}
                  />
                </Flex>
                <Flex mt={1} justifyContent='flex-start'>
                  <SteielTextInput
                    fieldName='email'
                    formProps={props}
                    label={capitalize(ls.email)}
                    labelStyle={{
                      weight: 600,
                      width: labelWidth,
                    }}
                    textFieldProps={{
                      placeholder: capitalize(ls.email),
                      required: true,
                      type: 'email',
                      error: Boolean(emailError),
                      size: 'small',
                      style: {
                        backgroundColor: isDarkMode ? colors.darkBackground2 : '#fff',
                        width: textFieldWidth,
                      },
                    }}
                  />
                </Flex>
                {auth.isAdmin ? (
                  <Flex
                    mt={1}
                    justifyContent={isMobileOrTablet ? 'center' : 'flex-start'}
                    alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                    flexDirection={isMobileOrTablet ? 'column' : 'row'}
                  >
                    <Body2
                      weight={600}
                      width={
                        isMobileOrTablet
                          ? labelWidth
                          : // @ts-ignore
                            labelWidth + 61
                      }
                      style={{ marginLeft: 2, marginBottom: 2 }}
                    >
                      {`${capitalize(ls.company)}:`}
                    </Body2>
                    <SteielAutocomplete
                      options={state.companiesOptions}
                      value={props.values.company}
                      onChange={(v) => {
                        props.setFieldValue('company', v);
                      }}
                      disabled={!auth.isAdmin}
                      textFieldProps={{ placeholder: `${capitalize(ls.company)} *` }}
                    />
                  </Flex>
                ) : null}
                {isNewUser &&
                auth.isAdmin &&
                props.values.role.toLowerCase() === ls.superTechnician.toLowerCase() ? (
                  <Flex mt={1} justifyContent='flex-start'>
                    <SteielTextInput
                      fieldName='companyDomain'
                      formProps={props}
                      label={capitalize(ls.companyDomain)}
                      labelStyle={{
                        weight: 600,
                        width: labelWidth,
                      }}
                      textFieldProps={{
                        placeholder: capitalize(ls.companyDomain),
                        required: true,
                        size: 'small',
                        style: {
                          backgroundColor: isDarkMode ? colors.darkBackground2 : '#fff',
                          width: textFieldWidth,
                        },
                      }}
                    />
                  </Flex>
                ) : null}
                {isNewUser &&
                auth.isAdmin &&
                props.values.role.toLowerCase() === ls.superTechnician.toLowerCase() &&
                props.values.companyDomain ? (
                  <Flex justifyContent='flex-start'>
                    <Box width={labelWidth} />
                    <Link href={companyDomainLink} style={{ textDecoration: 'none' }}>
                      <Body2
                        size='0.8rem'
                        color={colors.lightGrey}
                        style={{
                          textDecorationLine: 'underline',
                          fontStyle: 'italic',
                        }}
                      >
                        {companyDomainLink}
                      </Body2>
                    </Link>
                  </Flex>
                ) : null}
                {isNewUser ? (
                  <>
                    <Flex mt={1} justifyContent='flex-start'>
                      <SteielTextInput
                        fieldName='password'
                        formProps={props}
                        eyeIconColor={colors.lightGrey}
                        label={capitalize(ls.password)}
                        labelStyle={{
                          weight: 600,
                          width: labelWidth,
                        }}
                        textFieldProps={{
                          placeholder: capitalize(ls.password),
                          required: true,
                          error: Boolean(passwordError),
                          size: 'small',
                          style: {
                            backgroundColor: isDarkMode ? colors.darkBackground2 : '#fff',
                            width: textFieldWidth,
                          },
                        }}
                        isPassword
                      />
                    </Flex>
                    <Flex mt={1} justifyContent='flex-start'>
                      <SteielTextInput
                        fieldName='repeatPassword'
                        formProps={props}
                        eyeIconColor={colors.lightGrey}
                        label={capitalize(ls.confirmPassword)}
                        labelStyle={{
                          weight: 600,
                          width: labelWidth,
                        }}
                        textFieldProps={{
                          placeholder: capitalize(ls.confirmPassword),
                          required: true,
                          size: 'small',
                          style: {
                            backgroundColor: isDarkMode ? colors.darkBackground2 : '#fff',
                            width: textFieldWidth,
                          },
                        }}
                        isPassword
                      />
                    </Flex>
                  </>
                ) : null}
                <Flex
                  mt={1}
                  justifyContent='flex-start'
                  flexDirection={isMobileOrTablet ? 'column' : 'row'}
                  alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                >
                  <Box>
                    <Body2 weight={600} width={labelWidth || 0}>{`${capitalize(ls.type)}:`}</Body2>
                  </Box>
                  <Box width={isMobileOrTablet ? '100%' : 250} mt={isMobileOrTablet ? 0.5 : 0}>
                    <SteielSelect
                      allowedValues={roleAllowedValues}
                      onChange={(value) => {
                        props.setFieldValue('role', value);
                      }}
                      initialVal={props.values.role}
                    />
                  </Box>
                </Flex>
                <Flex
                  mt={1}
                  justifyContent='flex-start'
                  flexDirection={isMobileOrTablet ? 'column' : 'row'}
                  alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                >
                  <Box>
                    <Body2 weight={600} width={labelWidth || 0}>{`${capitalize(
                      ls.language,
                    )}:`}</Body2>
                  </Box>
                  <Box width={isMobileOrTablet ? '100%' : 250} mt={isMobileOrTablet ? 0.5 : 0}>
                    <SteielSelect
                      allowedValues={[
                        capitalize(ls.italian),
                        capitalize(ls.english),
                        capitalize(ls.french),
                        capitalize(ls.spanish),
                      ]}
                      onChange={(value) => {
                        props.setFieldValue('language', value);
                      }}
                      initialVal={props.values.language}
                    />
                  </Box>
                </Flex>
                {(auth.isAdmin || auth.isSuperTechnician) &&
                (props.values.role.toLowerCase() === ls.superTechnician.toLowerCase() ||
                  props.values.role.toLowerCase() === ls.admin.toLowerCase()) ? (
                  <Flex
                    mt={1}
                    justifyContent='flex-start'
                    flexDirection={isMobileOrTablet ? 'column' : 'row'}
                    alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                  >
                    <Box>
                      <Body2
                        weight={600}
                        width={isMobileOrTablet ? '100%' : labelWidth || 0}
                      >{`${capitalize(ls.insertLogo)}:`}</Body2>
                    </Box>
                    <Flex>
                      <Box maxWidth={150} mt={isMobileOrTablet ? 0.5 : 0}>
                        <Button
                          variant='text'
                          component='label'
                          fullWidth
                          size='small'
                          style={{
                            backgroundColor: colors.blue,
                            color: 'white',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            borderColor: isDarkMode ? colors.darkBackground2 : undefined,
                            borderRadius: 4,
                            paddingTop: 3,
                            paddingBottom: 3,
                            paddingLeft: 20,
                            paddingRight: 20,
                          }}
                        >
                          <Body1 style={{ color: '#fff' }} weight={400}>
                            {capitalize(ls.upload)}
                          </Body1>
                          <input
                            key={state.logoInputKey}
                            hidden
                            accept='image/png'
                            multiple
                            type='file'
                            onChange={(ev) => {
                              const file = ev.target?.files?.[0];
                              if (!file) {
                                setState({ logoFile: null });
                                return;
                              }
                              if (file.size > 1024 * 1024 * 5) {
                                setErrorFromServer(ls.invalidFileSize);
                                setState({ logoFile: null });
                                return;
                              }
                              setState({ logoFile: file });
                            }}
                          />
                        </Button>
                      </Box>
                      {state.logoFile ? (
                        <IconButton
                          onClick={() => {
                            setState({ logoFile: null, logoInputKey: Date.now() });
                          }}
                          size='small'
                        >
                          <CloseIcon fontSize='small' />
                        </IconButton>
                      ) : null}
                    </Flex>
                    {state.logoFile ? (
                      <Box>
                        <Body2
                          weight={400}
                          width={isMobileOrTablet ? '100%' : labelWidth || 0}
                          ml={isMobileOrTablet ? 0 : 1}
                        >
                          {state.logoFile.name}
                        </Body2>
                      </Box>
                    ) : null}
                  </Flex>
                ) : null}
                <Flex
                  mt={1}
                  justifyContent='flex-start'
                  flexDirection={isMobileOrTablet ? 'column' : 'row'}
                  alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                >
                  <Box alignSelf='flex-start'>
                    <Body2
                      weight={600}
                      mt={isMobileOrTablet ? 0 : 1}
                      width={labelWidth || 0}
                    >{`${capitalize(ls.notes)}:`}</Body2>
                  </Box>
                  <Box width={isMobileOrTablet ? '100%' : undefined}>
                    <Box width={isMobileOrTablet ? '100%' : textFieldWidth}>
                      <TextareaAutosize
                        minRows={3}
                        maxRows={10}
                        placeholder={ls.insertNote}
                        style={{
                          width: '100%',
                          fontFamily: 'Roboto Condensed',
                          fontWeight: 300,
                          fontSize: '1.1rem',
                          padding: 12,
                          color: isDarkMode ? colors.lightGrey : colors.blue,
                          backgroundColor: isDarkMode ? colors.darkBackground2 : undefined,
                          borderColor: isDarkMode ? '#5a5c6f' : '#bdbdbd',
                        }}
                        value={props.values.notes}
                        onChange={(e) => {
                          props.setFieldValue('notes', e.target.value);
                        }}
                      />
                    </Box>
                  </Box>
                </Flex>
              </Box>
              {firstError ? (
                <ErrorMessage
                  containerStyle={{
                    my: 2,
                  }}
                  message={firstError}
                />
              ) : (
                <Box />
              )}
              {props.isSubmitting && (
                <Box my={2}>
                  <LinearProgress />
                </Box>
              )}
              <Flex
                mt={3}
                mb={2}
                width={
                  isMobileOrTablet
                    ? undefined
                    : // @ts-ignore
                      textFieldWidth + labelWidth
                }
              >
                <Box flex={1} />
                <Box width={150}>
                  <SteielButton
                    text={isNewUser ? ls.create : ls.apply}
                    type='submit'
                    disabled={props.isSubmitting}
                    fullWidth
                    style={{
                      backgroundColor: colors.green,
                      borderColor: colors.green,
                    }}
                  />
                </Box>
              </Flex>
            </Form>
          );
        }}
      </Formik>
      <SteielDialog
        title={capitalize(ls.changesCompleted, false)}
        content={
          isNewUser ? capitalize(ls.userCreatedSuccessfully) : capitalize(ls.dataSavedSuccessfully)
        }
        onClose={() => {
          setState({ showSuccessDialog: false });
          if (isNewUser) {
            navigate(PageName.users);
          }
        }}
        onOkActionClick={() => {
          setState({ showSuccessDialog: false });
          if (isNewUser) {
            navigate(PageName.users);
          }
        }}
        open={state.showSuccessDialog}
        okLabel={capitalize(ls.ok)}
      />
    </Box>
  );
};

export default EditUserForm;
