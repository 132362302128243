import React from 'react';
import { Box } from '@mui/material';
import GaugeChartComp, { GaugeChartProps as GaugeChartCompProps } from 'react-gauge-chart';
import { Body2 } from '../ui/Typography';
import { colors } from '../../theme';
import Center from '../ui/Center';
import { ls } from '../../i18n/translations';
import Flex from '../ui/Flex';
import { useDarkMode } from '../../hook/useDarkMode';

interface GaugeChartProps {
  min?: number;
  max?: number;
  unitOfMeasure?: string;
  setpoint?: number;
  readonly?: boolean;
  currentValue?: number;
  h?: number;
  w?: number;
  decimal?: number;
  hideSetpoint?: boolean;
}

const GaugeChart: React.FC<GaugeChartProps & GaugeChartCompProps> = ({
  min = 0,
  max = 100,
  unitOfMeasure,
  setpoint,
  readonly = false,
  currentValue,
  h = 180,
  w = 350,
  decimal = 2,
  hideSetpoint = false,
  ...props
}) => {
  const { isDarkMode } = useDarkMode();
  const percValue =
    (currentValue === undefined ? 0 : (currentValue - min) / ((max - min) / 100 || 1)) / 100;
  return (
    <Box position='relative'>
      <Box>
        <GaugeChartComp
          nrOfLevels={6}
          colors={['#fdebed', '#ea3a4a']}
          arcWidth={0.3}
          needleBaseColor='#263352'
          needleColor='#9298a8'
          hideText
          animate={false}
          animateDuration={1500}
          animDelay={50}
          percent={percValue}
          arcPadding={0.02}
          {...props}
          style={{ width: w, height: h, ...props.style }}
        />
      </Box>
      {!readonly ? (
        <Box position='absolute' bottom={8} left={39}>
          <Flex width={w * 0.775}>
            <Center
              borderRadius={1}
              border='1px solid'
              borderColor={colors.lightGrey}
              width={50}
              height={28}
            >
              <Body2
                // mt={0.5}
                mb={-0.2}
              >
                {min ?? '--'}
                {/* {unitOfMeasure} */}
              </Body2>
            </Center>
            <Center mt={0.5} flex={1}>
              {!hideSetpoint ? (
                <Center>
                  <Body2 color={colors.grey} size='0.9rem'>
                    {ls.setpoint.toUpperCase()}
                    {':'}
                  </Body2>
                  <Body2 color={isDarkMode ? colors.lightBlue : colors.blue} ml={0.5} size='0.9rem'>
                    {setpoint?.toFixed(decimal) ?? '--'}
                    {unitOfMeasure}
                  </Body2>
                </Center>
              ) : null}
            </Center>
            <Center
              borderRadius={1}
              border='1px solid'
              borderColor={colors.lightGrey}
              width={50}
              height={28}
            >
              <Body2
                // mt={0.5}
                mb={-0.2}
              >
                {max ?? '--'}
                {/* {unitOfMeasure} */}
              </Body2>
            </Center>
          </Flex>
        </Box>
      ) : null}

      {/* <Box position='absolute' bottom={8} right={35}>
        <Center
          borderRadius={1}
          border='1px solid'
          borderColor={colors.lightGrey}
          p={0.5}
          width={50}
          height={30}
        >
          <Body2 mt={0.5}>
            {max ?? '--'}
            {unitOfMeasure}
          </Body2>
        </Center>
      </Box> */}
      {/* <Box position='absolute' bottom={8} left={99}>
        <Center>
          <Body2 color={colors.grey}>
            {ls.setpoint.toUpperCase()}
            {':'}
          </Body2>
          <Body2 color={colors.blue}>
            {setpoint ?? '--'}
            {unitOfMeasure}
          </Body2>
        </Center>
      </Box> */}
    </Box>
  );
};

export default GaugeChart;
