export const en = {
  no: 'No',
  yes: 'Yes',
  userNotFound: 'User not found',
  invalidCredentials: 'Invalid credentials',
  badOldPassword: 'Wrong old password',
  somethingWentWrong: 'Something went wrong',
  sww: 'Something went wrong',
  tooReqError: 'Too many requests, try again later',
  fieldRequired: 'Required field',
  invalidEmail: 'Invalid e-mail',
  passwordTooShort: 'Password must include at least {0} characters',
  login: 'Login',
  signIn: 'Sign in',
  email: 'E-mail',
  password: 'Password',
  dashboard: 'Dashboard',
  users: 'Users',
  customers: 'Customers',
  managers: 'Managers',
  totalDevices: 'Total devices',
  onlineDevices: 'Online devices',
  devicesOnAlarm: 'Devices on alarm',
  deviceStatus: 'Status of devices',
  search: 'Search',
  mac: 'Mac',
  model: 'Model',
  registrationNumber: 'Serial number',
  alarms: 'Alarms',
  warnings: 'Warnings',
  status: 'Status',
  lastConnection: 'Last connection',
  details: 'Details',
  summary: 'Summary',
  viewAll: 'View all',
  customersList: 'List of customers',
  managerList: 'List of managers',
  id: 'ID',
  nameSurname: 'Name and surname',
  city: 'Town',
  role: 'Role',
  notAuthorized: 'Not authorized',
  confirm: 'Confirm',
  disconnectQuestion: 'Do you want to logout?',
  logout: 'Logout',
  menu: 'Menu',
  online: 'Online',
  offline: 'Offline',
  actives: 'Active',
  user: 'User',
  admin: 'Administrator',
  technician: 'Technician',
  alarmsHistory: 'Alarm history',
  date: 'Date',
  dates: 'Dates',
  name: 'Name',
  type: 'Type',
  alarm: 'Alarm',
  warning: 'Warning',
  forgetPasswordQuestion: 'Password forgotten?',
  signUp: 'Register',
  registration: 'Registration',
  attention: 'Caution',
  ok: 'OK',
  acceptDataPrivacyAgreementWarn: 'You must consent to your data processing',
  acceptDataMarketingAgreementWarn: 'Select an option for data use for marketing purpose',
  passwordMismatch: 'Passwords do not match',
  repeatPassword: 'Repeat password',
  telephone: 'Telephone',
  nation: 'Country',
  company: 'Company',
  dataAgreement:
    'Flagging the box you accept the privacy policy terms as per EU Directive 679/2016 and declare to be of age',
  agree: 'I agree',
  notAgree: "I don't agree",
  marketingAgreement:
    'Flagging the box you accept the use of your personal data for marketing purpose by STEIEL',
  privacyTreatment: 'Privacy policy',
  verifyEmail: 'Verify e-mail',
  emailSentToYourEmail: 'A verification e-mail has been sent to:',
  emailNotReceivedQuestion: "You didn't receive the e-mail?",
  sendAgain: 'Send again',
  verificationEmailSent: 'Verification e-mail sent',
  lostPasswordQuestion: 'Do you forgot the password?',
  lostPasswordInstructions:
    'Enter the e-mail address to send the instructions for new password setting',
  resetPassword: 'Reset password',
  goBack: 'Return back',
  emailSentToYourAddress: 'An e-mail has be sent to your address',
  passwordChanged: 'Password modified',
  notice: 'Notice',
  changePassword: 'Change password',
  settings: 'Settings',
  add: 'Add',
  profile: 'Profile',
  security: 'Security',
  saveChanges: 'Save changes',
  profileSavedSuccessfully: 'Profile saved',
  dataSavedSuccessfully: 'Data saved',
  oldPassword: 'Old password',
  passwordChangedSuccessfully: 'Password changed',
  counterResettedSuccessfully: 'Counter reset',
  at: 'at',
  noDataFound: 'No data found',
  deviceManagement: 'Devices management',
  usersManagement: 'Users management',
  version: 'Version',
  dateAndTime: 'Date/Time',
  place: 'Place',
  comeback: 'Return back',
  setup: 'Setup',
  default: 'Default',
  interface: 'Interface',
  temperature: 'Temperature',
  ph: 'pH',
  chlorine: 'Chlorine',
  totalChlorine: 'Total chlorine',
  freeChlorine: 'Free chlorine',
  combinedChlorine: 'Combined chlorine',
  table: 'Table',
  charts: 'Charts',
  setpoint: 'Setpoint',
  customizeMeasurements: 'Customize layout',
  active: 'Active',
  deactivated: 'Deactivated',
  minAcceptableValue: 'Min acceptable value',
  maxAcceptableValue: 'Max acceptable value',
  outputs: 'Outputs',
  inputs: 'Inputs',
  on: 'On',
  off: 'Off',
  value: 'Value',
  exportTable: 'Export table',
  exportAlarms: 'Export alarms',
  localDateTime: 'Date/Time (local)',
  notes: 'Notes',
  channel: 'Channel',
  description: 'Description',
  deviceDetails: 'Device details',
  measure: 'Measurement',
  enable: 'Enable',
  unitOfMeasure: 'Measure unit',
  scaleStart: 'Scale start',
  bottomScale: 'Full scale',
  minAlarm: 'Min alarm',
  maxAlarm: 'Max alarm',
  minAcceptableLimit: 'Min acceptable limit',
  maxAcceptableLimit: 'Max acceptable limit',
  decimals: 'Decimals',
  selectWidget: 'Select Widget',
  notifications: 'Notifications',
  notificationsDelay: 'Notification delay (minutes)',
  recipientList: 'List of recipients',
  customizeNotification: 'Customize notification',
  apply: 'Apply',
  view: 'View',
  searchPlaceholder: 'Search ID, name, place',
  filter: 'Filter',
  withAlarm: 'Alarm',
  notWithAlarm: 'No alarm',
  cancel: 'Cancel',
  deviceDefaultSettingsResetQuestion: 'Do you want to restore default settings?',
  samplingTime: 'Sampling time (min)',
  timeZone: 'Time zone',
  macAddress: 'mac address',
  tool: 'Instrument',
  newCustomer: 'New customer',
  newManager: 'New manager',
  locality: 'Place',
  notificationManagement: 'Notification management',
  manageNotifications: 'Manage notifications',
  sendEmail: 'Send e-mail',
  usersList: 'List of users',
  modifyCustomer: 'Modify customer',
  modifyManager: 'Modify manager',
  newUser: 'New user',
  deleteUser: 'Delete user',
  notePlaceholder: 'Enter note',
  insertLogo: 'Enter logo',
  language: 'Language',
  file: 'File',
  create: 'Create',
  modify: 'Edit',
  update: 'Update',
  createNewCustomer: 'Create new customer',
  createNewManager: 'Create new manager',
  viewer: 'Viewer',
  superTechnician: 'Administrator no.2',
  supertechnician: 'Administrator no.2',
  newDevice: 'New device',
  createNewDevice: 'Create new device',
  installer: 'Installer',
  newModule: 'New module',
  modifyDevice: 'Modify device',
  searchByNamePlace: 'Search by name, place',
  searchByNameSurnameUsername: 'Search by name, surname, username ...',
  surname: 'Surname',
  username: 'Username',
  modifyUser: 'Modify user',
  confirmPassword: 'Confirm password',
  insertNote: 'Enter note',
  createNewUser: 'Create new customer',
  customersManagement: 'Customers management',
  customer: 'Customer',
  manager: 'Manager',
  send: 'Send',
  modifyPassword: 'Change password',
  newPassword: 'New password',
  passwordRecoveryInstructions: 'Enter your e-mail address to receive the link for password change',
  userProfile: 'User profile',
  italian: 'Italian',
  english: 'English',
  currentPassword: 'Current password',
  new: 'New',
  deviceInterfaceChartTitle: 'SwimEx Cold Plunge Pool',
  deviceInterfaceChartMobileTip: 'Put the device horizontally for a better view of the chart',
  deviceInterfaceChartTip: 'Place the pointer on the chart to view data',
  dtBodyNoMatch: 'No data found',
  dtBodyTooltip: 'Sort',
  dtBodyColumnHeaderTooltip: 'Sort by',
  dtNextPage: 'Next page',
  dtPreviousPage: 'Previous page',
  dtRowsPerPage: 'Rows per page:',
  jumpToPage: 'Go to page:',
  dtDisplayRows: 'of',
  dtToolbarSearch: 'Search',
  dtToolbarDownloadCsv: 'Download CSV',
  dtToolbarPrint: 'Print',
  dtToolbarViewColumns: 'View columns',
  dtToolbarFilterTable: 'Filter table',
  dtFilterAll: 'All',
  dtFilterTitle: 'FILTERS',
  dtFilterReset: 'RESET',
  dtViewColumnsTitle: 'Show columns',
  dtViewColumnsTitleAria: 'Show/hide columns',
  dtSelectedRowsText: 'Selected rows',
  dtSelectedRowsDelete: 'Delete',
  dtSelectedRowsDeleteAria: 'Delete selected rows',
  success: 'Successful',
  recoveryPasswordEmailSent: 'E-mail sent for password recovery',
  french: 'French',
  noInterfaceConnected: 'No interface connected',
  hthInterfaceName: 'CYCL’EAU First',
  hthProInterfaceName: 'CYCL’EAU Pro',
  input_pHLvl: 'pH level',
  input_cl2Lvl: 'Chlorine level',
  input_lvl3: 'Level 3',
  input_flow: 'Flow',
  input_cadOff: 'CAD (Off)',
  output_out1: 'Out 1',
  output_out2: 'Out 2',
  output_alarm: 'Alarm relay',
  alarm_lowPHLvl: 'Low pH level',
  alarm_lowCl2Lvl: 'Low chlorine level',
  alarm_flow: 'Flow',
  alarm_onOffKey: 'ON/OFF key',
  alarm_inputCadOff: 'CAD Off input',
  alarm_maxPHDosage: 'Max pH dosage',
  alarm_maxCl2Dosage: 'Max chlorine dosage',
  alarm_startDelay: 'Start-up delay',
  alarm_pHMin: 'Min pH',
  alarm_phMax: 'Max pH',
  alarm_cl2Min: 'Min Cl2',
  alarm_cl2Max: 'Max Cl2',
  noMeasurementFound: 'No measurement found',
  settingsSavedSuccessfully: 'Settings saved',
  parameters: 'Parameters',
  parameter: 'Parameter',
  noParametersFound: 'No parameter found',
  setpointPHParamDesc: 'Setpoint 1 (OUT_pH)',
  setpointCl2ParamDesc: 'Setpoint 2 (OUT_Cl2)',
  minPHAlarmParamDesc: 'Alarm (alert) for min pH',
  maxPHAlarmParamDesc: 'Allarme (avviso) max. pH',
  minFreeChlorineParamDesc: 'Alarm (alert) for min chlorine',
  maxFreeChlorineParamDesc: 'Alarm (alert) for max chlorine',
  maxCombChlorineParamDesc: 'Alarm (alert) for max combined chlorine',
  settingTypePHParamDesc: 'OUT_pH Adjustment type',
  settingTypeCl2ParamDesc: 'OUT_Cl2 Adjustment type',
  hysteresisPHParamDesc: 'Hysteresis OUT_pH',
  hysteresisCl2ParamDesc: 'Hysteresis OUT_Cl2',
  sensePHParamDesc: 'pH sens',
  startDelayParamDesc: 'Start delay (sec)',
  flowDelayParamDesc: 'Delay after flow restoring (sec)',
  maxInjectionTimeParamDesc: 'Max injection time (min)',
  pHTankLvlParamDesc: 'Level of pH tank',
  chlorineTankLvlParamDesc: 'Level of chlorine tank',
  flowSensorParamDesc: 'Flow sensor',
  totalChlorineParamDesc: 'Total chlorine',
  languageParamDesc: 'Langue',
  alarmRelayParamDesc: 'Alarm relay',
  serialLineParamDesc: 'Serial line',
  serialLineCommSpeedParamDesc: 'Communication speed of serial line',
  modbusCommAddrParamDesc: 'Modbus communication address',
  passwordParamDesc: 'Password',
  proportional: 'Proportional',
  onOff: 'On-Off',
  alkaline: 'Alkaline',
  acid: 'Acid',
  spanish: 'Spanish',
  ascii: 'Ascii',
  modbus: 'Modbus',
  min: 'min',
  max: 'max',
  onlineWithAlarm: 'Online + alarm',
  macAddressNotFound: 'mac device not found',
  invalidCompanyValue: 'Invalid Company',
  deleteDevice: 'Delete device',
  deleteDeviceQuestion: 'Do you want to delete device?',
  userCreatedSuccessfully: 'User created',
  deleteUserQuestion: 'Do you want to delete user {0}?',
  deviceCreatedSuccessfully: 'Device created',
  noMeasurementsFoundOrEnabled: 'No measurement enabled or found',
  companyDomain: 'Company domain',
  from: 'from',
  to: 'to',
  alarmsFixed: 'Solved alarms',
  badInputData: 'Wrong input data',
  changesCompleted: 'Changes completed',
  upload: 'Upload',
  invalidFileSize: 'Invalid file size',
  invalidFileType: 'Invalid file type',
  alarm_maxTimeK1: 'Max time K1',
  alarm_maxTimeK2: 'Max time K2',
  alarm_maxTimeK4: 'Max time K4',
  alarm_maxTimeK5: 'Max time K5',
  alarm_ignitionDelay: 'Start-up delay',
  alarm_pHStabilizer: 'pH stabilization',
  alarm_clock: 'Clock',
  alarm_zeroChlorine: 'Zero chlorine',
  alarm_redox: 'ORP',
  alarm_superchlorination: 'Hyperchlorination',
  alarm_notUsed1: 'Not used 1',
  alarm_notUsed2: 'Not used 2',
  alarm_notUsed3: 'Not used 3',
  alarm_notUsed4: 'Not used 4',
  alarm_richAssist: 'Service required',
  alarm_toolBlock: 'Instrument block',
  output_k1: 'K1',
  output_k2: 'K2',
  output_k3: 'K3',
  output_k4: 'K4',
  output_k5: 'K5',
  output_ev: 'EV',
  output_perist1: 'Perist1',
  output_perist2: 'Perist2',
  output_perist3: 'Perist3',
  output_mixer: 'Mixer 1',
  input_lvl1: 'Level 1',
  input_lvl2: 'Level 2',
  input_off: 'OFF input',
  input_imp: 'IMP input',
  P01MC14ParamDesc: 'Measurement associated to relay output K1',
  P02MC14ParamDesc: 'Output type of relay K1',
  P03MC14ParamDesc: '',
  P04MC14ParamDesc: '',
  P05MC14ParamDesc: '',
  P06MC14ParamDesc: '',
  P07MC14ParamDesc: 'Max dosing time alarm for K1 (h : min)',
  P08MC14ParamDesc: 'OFF conditions for relay K1',
  P09MC14ParamDesc: 'Measurement associated to relay output K2',
  P10MC14ParamDesc: 'Output type of relay K2',
  P11MC14ParamDesc: '',
  P12MC14ParamDesc: '',
  P13MC14ParamDesc: '',
  P14MC14ParamDesc: '',
  P15MC14ParamDesc: 'Max dosing time alarm for K2 (h : min)',
  P16MC14ParamDesc: 'OFF conditions for relay K2',
  P17MC14ParamDesc: '',
  P18MC14ParamDesc: '',
  P19MC14ParamDesc: '',
  P20MC14ParamDesc: '',
  P21MC14ParamDesc: '',
  P22MC14ParamDesc: '',
  P23MC14ParamDesc: '',
  P24MC14ParamDesc: '',
  P25MC14ParamDesc: '',
  P26MC14ParamDesc: '',
  P27MC14ParamDesc: '',
  P28MC14ParamDesc: '',
  P29MC14ParamDesc: '',
  P30MC14ParamDesc: '',
  P31MC14ParamDesc: '',
  P32MC14ParamDesc: '',
  P33MC14ParamDesc: '',
  P34MC14ParamDesc: '',
  P35MC14ParamDesc: '',
  P36MC14ParamDesc: '',
  P37MC14ParamDesc: '',
  P38MC14ParamDesc: '',
  P39MC14ParamDesc: '',
  P40MC14ParamDesc: '',
  P41MC14ParamDesc: '',
  P42MC14ParamDesc: '',
  P43MC14ParamDesc: '',
  P44MC14ParamDesc: '',
  P45MC14ParamDesc: '',
  P46MC14ParamDesc: '',
  P47MC14ParamDesc: '',
  P48MC14ParamDesc: '',
  P49MC14ParamDesc: '',
  P50MC14ParamDesc: '',
  P51MC14ParamDesc: '',
  P52MC14ParamDesc: '',
  P53MC14ParamDesc: '',
  P54MC14ParamDesc: '',
  P55MC14ParamDesc: '',
  P56MC14ParamDesc: '',
  P57MC14ParamDesc: '',
  P58MC14ParamDesc: '',
  P59MC14ParamDesc: '',
  P60MC14ParamDesc: '',
  P61MC14ParamDesc: '',
  P62MC14ParamDesc: '',
  P63MC14ParamDesc: '',
  P64MC14ParamDesc: '',
  P65MC14ParamDesc: '',
  P66MC14ParamDesc: '',
  P67MC14ParamDesc: '',
  P68MC14ParamDesc: '',
  P69MC14ParamDesc: '',
  P70MC14ParamDesc: '',
  P71MC14ParamDesc: '',
  P72MC14ParamDesc: '',
  P73MC14ParamDesc: '',
  P74MC14ParamDesc: '',
  P75MC14ParamDesc: '',
  P76MC14ParamDesc: '',
  P77MC14ParamDesc: '',
  P78MC14ParamDesc: '',
  P79MC14ParamDesc: '',
  P80MC14ParamDesc: '',
  P81MC14ParamDesc: '',
  P82MC14ParamDesc: '',
  P83MC14ParamDesc: '',
  P84MC14ParamDesc: '',
  P85MC14ParamDesc: '',
  P86MC14ParamDesc: '',
  P87MC14ParamDesc: '',
  P88MC14ParamDesc: '',
  P89MC14ParamDesc: '',
  P90MC14ParamDesc: '',
  P91MC14ParamDesc: '',
  P92MC14ParamDesc: '',
  P93MC14ParamDesc: '',
  P94MC14ParamDesc: '',
  notAvailable: 'Not available',
  disabled: 'Disabled',
  closingOverthresholds: 'Close when thresholds are exceeded',
  openingOverthreshold: 'Open when thresholds are exceeded',
  upward: 'Upwards',
  downward: 'Downwards',
  hourlyOperation: 'Timed operations',
  unknown: 'Unknown',
  mc14evoPHRxCle12TcInterfaceName: 'MC14 EVO pH/RX/CLE12/T',
  mc14evoPHRxCacTcInterfaceName: 'MC14 EVO pH/RX/CAC/T',
  mc14evo_4CanaliTcInterfaceName: 'MC14 EVO 4 channels + T',
  mco14_3SezCl2InterfaceName: 'MCO14 EVO 3 sections (Cl2)',
  mco14_5SezCl2ClTClCInterfaceName: 'MCO14 EVO 5 sections (Cl2 + ClT + ClC)',
  nsp161InterfaceName: 'NOVA NSP161',
  nsp161CMinInterfaceName: 'NOVA NSP161 c/min',
  nsp161MAInterfaceName: 'NOVA NSP161 mA',
  nsp162PHRxInterfaceName: 'NOVA NSP162 pH/RX',
  nsp163ACle11_12InterfaceName: 'NOVA NSP163A CLE11/12',
  nsp163BCle16InterfaceName: 'NOVA NSP163B CLE16',
  pHRxTcInterfaceName: 'EF315 pH/RX/T',
  pHClTcInterfaceName: 'EF315 pH/CL/T',
  pHCacTcInterfaceName: 'EF315 pH/CAC/T',
  channels: 'Channels',
  sectionMin: 'Sections',
  loggedInAs: 'Logged-in as',
  badRangeInput: 'Invalid values',
  open: 'Open',
  all: 'All',
  moduleComunicationTimeoutErrDesc: 'Check interface wiring and power supply',
  updateParamFailed: 'Parameter upgrading failed',
  saveChangesQuestion: 'Do you want to save changes?',
  secondaryParameters: 'Secondary parameters',
  P03MC14ParamDescOption1: 'Threshold to be reached by relay K1',
  P03MC14ParamDescOption2: 'Central value for alarm threshold of K1',
  P03MC14ParamDescOption3: 'First activation time of K1',
  P04MC14ParamDescOption1: 'Hysteresis relay K1',
  P04MC14ParamDescOption2: 'Hysteresis above and below threshold for K1',
  P04MC14ParamDescOption3: 'First deactivation time of K1',
  P11MC14ParamDescOption1: 'Threshold to be reached by relay K2',
  P11MC14ParamDescOption2: 'Central value for alarm threshold of K2',
  P11MC14ParamDescOption3: 'First activation time of K2',
  P12MC14ParamDescOption1: 'Hysteresis relay K2',
  P12MC14ParamDescOption2: 'Hysteresis above and below threshold for K2',
  P12MC14ParamDescOption3: 'First deactivation time of K2',
  P27MC14ParamDescOption1: 'Threshold to be reached by relay K4',
  P27MC14ParamDescOption2: 'Central value for alarm threshold of K4',
  P27MC14ParamDescOption3: 'First activation time of K4',
  P28MC14ParamDescOption1: 'Hysteresis relay K4',
  P28MC14ParamDescOption2: 'Hysteresis above and below threshold for K4',
  P28MC14ParamDescOption3: 'First deactivation time of K4',
  alarm_lvl: 'Level',
  alarm_extcons: 'Ext. Cons.',
  alarm_stop: 'Stop',
  alarm_urOr: 'UR/OR',
  alarm_timer: 'Timer',
  alarm_maintenance: 'Maintenance',
  alarm_autoadesco: 'Self-priming',
  alarm_internalTemp: 'Internal temperature',
  alarm_maxDoseTime: 'Max dosing time',
  alarm_maxAccPulses: 'Max storable pulses',
  alarm_sampleWater: 'Sample water',
  alarm_clockKO: 'Clock KO',
  output_magnetStatus: 'Magnet status',
  output_relayAlarm: 'Status Relay',
  input_lvl: 'level',
  input_extcons: 'Ext. Cons.',
  P01NSPParamDesc: 'Language',
  P02NSPParamDesc: '',
  P03NSPParamDesc: '',
  P04NSPParamDesc: '',
  P05NSPParamDesc: '',
  P06NSPParamDesc: '',
  P07NSPParamDesc: 'Max frequency',
  P08NSPParamDesc: '',
  P09NSPParamDesc: '',
  P10NSPParamDesc: '',
  P11NSPParamDesc: 'Setpoint',
  P12NSPParamDesc: 'Hysteresi / Proportional band',
  P13NSPParamDesc: '',
  P14NSPParamDesc: '',
  P15NSPParamDesc: '',
  P16NSPParamDesc: '',
  P17NSPParamDesc: '',
  P18NSPParamDesc: '',
  P19NSPParamDesc: '',
  P20NSPParamDesc: '',
  P21NSPParamDesc: '',
  P22NSPParamDesc: '',
  P23NSPParamDesc: '',
  P24NSPParamDesc: '',
  P25NSPParamDesc: '',
  P26NSPParamDesc: '',
  P27NSPParamDesc: '',
  P28NSPParamDesc: '',
  P29NSPParamDesc: '',
  P30NSPParamDesc: '',
  P31NSPParamDesc: '',
  P32NSPParamDesc: '',
  P33NSPParamDesc: '',
  P34NSPParamDesc: '',
  P35NSPParamDesc: '',
  P36NSPParamDesc: '',
  P37NSPParamDesc: '',
  P38NSPParamDesc: '',
  P39NSPParamDesc: '',
  P40NSPParamDesc: '',
  P41NSPParamDesc: '',
  P42NSPParamDesc: '',
  P43NSPParamDesc: '',
  P44NSPParamDesc: '',
  P45NSPParamDesc: '',
  P46NSPParamDesc: '',
  P47NSPParamDesc: '',
  P48NSPParamDesc: '',
  P49NSPParamDesc: '',
  P50NSPParamDesc: '',
  P51NSPParamDesc: '',
  P52NSPParamDesc: '',
  P53NSPParamDesc: '',
  P54NSPParamDesc: '',
  P55NSPParamDesc: '',
  P56NSPParamDesc: '',
  P57NSPParamDesc: '',
  P58NSPParamDesc: '',
  P59NSPParamDesc: '',
  P60NSPParamDesc: '',
  P61NSPParamDesc: '',
  P62NSPParamDesc: '',
  P63NSPParamDesc: '',
  P64NSPParamDesc: '',
  P65NSPParamDesc: '',
  P66NSPParamDesc: '',
  P67NSPParamDesc: '',
  P68NSPParamDesc: '',
  P69NSPParamDesc: '',
  P70NSPParamDesc: '',
  P71NSPParamDesc: '',
  P72NSPParamDesc: '',
  P73NSPParamDesc: '',
  P74NSPParamDesc: '',
  P75NSPParamDesc: '',
  P76NSPParamDesc: '',
  P77NSPParamDesc: '',
  P78NSPParamDesc: '',
  P79NSPParamDesc: '',
  P80NSPParamDesc: '',
  P81NSPParamDesc: '',
  P82NSPParamDesc: '',
  P83NSPParamDesc: '',
  P84NSPParamDesc: '',
  P85NSPParamDesc: '',
  P86NSPParamDesc: '',
  P87NSPParamDesc: '',
  P88NSPParamDesc: '',
  P89NSPParamDesc: '',
  P90NSPParamDesc: '',
  P91NSPParamDesc: '',
  P92NSPParamDesc: '',
  P93NSPParamDesc: '',
  P94NSPParamDesc: '',
  P95NSPParamDesc: '',
  alarm_lvl1: 'Level 1',
  alarm_lvl2: 'Level 2',
  alarm_maxDosageTimeOut1: 'Max dosage time Out 1',
  alarm_maxDosageTimeOut2: 'Max dosage time Out 2',
  alarm_urOrMeasure1: 'UR/OR Measure 1',
  alarm_urOrMeasure2: 'UR/OR Measure 2',
  alarm_maintenancePump1: 'Pump maintenance 1',
  alarm_maintenancePump2: 'Pump maintenance 2',
  alarm_recalibrateChlorineProbeAfter24h: 'Recalibrate the chlorine probe after 24 hours',
  alarm_halvedSetpointForBadCalib: 'Setpoint halved',
  input_stop: 'Stop',
  input_ignitionDelay: 'Start-up delay',
  input_pHStabilizer: 'pH stabilization',
  input_maintenancePump1: 'Pump maintenance 1',
  input_maintenancePump2: 'Pump maintenance 2',
  input_recalibrateChlorineProbeAfter24h: 'Recalibrate the chlorine probe after 24 hours',
  input_halvedSetpointForBadCalib: 'Setpoint halved',
  P01EFParamDesc: '',
  P02EFParamDesc: '',
  P03EFParamDesc: 'Setpoint (Out 1)',
  P04EFParamDesc: 'Hysteresi / Proportional band (Out 1)',
  P05EFParamDesc: 'Max frequency (Out 1)',
  P06EFParamDesc: '',
  P07EFParamDesc: '',
  P08EFParamDesc: '',
  P09EFParamDesc: '',
  P10EFParamDesc: 'Setpoint (Out 2)',
  P11EFParamDesc: 'Hysteresi / Proportional band (Out 2)',
  P12EFParamDesc: 'Max frequency (Out 2)',
  P13EFParamDesc: '',
  P14EFParamDesc: '',
  P15EFParamDesc: '',
  P16EFParamDesc: '',
  P17EFParamDesc: '',
  P18EFParamDesc: '',
  P19EFParamDesc: '',
  P20EFParamDesc: '',
  P21EFParamDesc: '',
  P22EFParamDesc: '',
  P23EFParamDesc: '',
  P24EFParamDesc: '',
  P25EFParamDesc: '',
  P26EFParamDesc: '',
  P27EFParamDesc: '',
  P28EFParamDesc: '',
  P29EFParamDesc: '',
  P30EFParamDesc: '',
  P31EFParamDesc: '',
  P32EFParamDesc: '',
  P33EFParamDesc: '',
  P34EFParamDesc: '',
  eventsLog: 'Events LOG',
  events: 'Events',
  alarm_new_stop: 'Strumento OFF',
  alarm_new_inputCadOff: 'Ingresso CAD (OFF)',
  alarm_new_flow: 'Allarme Flow',
  alarm_new_urOr: 'Allarme UR/OR',
  alarm_new_lowPHLvl: 'Allarme Min livello pH',
  alarm_new_lowCl2Lvl: 'Allarme Min livello Cl2',
  alarm_new_maxPHDosage: 'Allarme Max dosaggio pH',
  alarm_new_maxCl2Dosage: 'Allarme Max dosaggio Cl2',
  alarm_new_pHMin: 'Avviso minimo pH',
  alarm_new_phMax: 'Avviso massimo pH',
  alarm_new_cl2Min: 'Avviso minimo Cl2',
  alarm_new_cl2Max: 'Avviso massimo Cl2',
  alarm_new_clock: 'Timer',
  alarm_new_maxTimeK1: 'Allarme Max TK1',
  alarm_new_maxTimeK2: 'Allarme Max TK2',
  alarm_new_maxTimeK4: 'Allarme Max TK4',
  alarm_new_maxTimeK5: 'Allarme Max TK5',
  alarm_new_redox: 'Allarme Redox',
  alarm_new_zeroChlorine: 'Allarme 0 Cl2',
  output_mixer2: 'Mixer 2',
  alarm_extConsensus: 'Consenso esterno',
  alarm_lvl1MinAlarm: 'Allarme Min livello 1',
  alarm_lvl2MinAlarm: 'Allarme Min livello 2',
  alarm_lvl3MinAlarm: 'Allarme Min livello 3',
  alarm_off: 'Off',
  alarm_new_maintenance: 'Rich. Manutenzione',
  alarm_new_timer: 'Timer control',
  alarm_new_lvl: 'Allarme Livello',
  alarm_new_extcons: 'Allarme Ext.Cons',
  alarm_new_internalTemp: 'Allarme temperatura interna',
  alarm_new_clockKO: 'Allarme Orologio KO',
  alarm_new_sampleWater: 'Sample water',
  alarm_new_maxDoseTime: 'Allarme Max Dosaggio',
  alarm_new_maintenancePump1: 'Manutenzione P1',
  alarm_new_maintenancePump2: 'Manutenzione P2',
  alarm_new_urOrMeasure1: 'Allarme UR/OR Misura 1',
  alarm_new_urOrMeasure2: 'Allarme UR/OR Misura 2',
  alarm_new_lvl1: 'Allarme Livello 1',
  alarm_new_lvl2: 'Allarme Livello 2',
  alarm_new_maxDosageTimeOut1: 'Allarme MAX T P1',
  alarm_new_maxDosageTimeOut2: 'Allarme MAX T P2',
  statuses: 'Statuses',
  theme: 'theme',
  toolStatus: 'Tool status',
  minWarningLimit: 'Min ideal value',
  maxWarningLimit: 'Max ideal value',
  canNotSaveOfflineOrErrModule:
    'settings cannot be saved when the module is offline or has a communication error',
  ew2PoolInterfaceName: 'Evolution EW² Pool',
  ew2Pool_pHOrpTcInterfaceName: 'EW² Pool pH/ORP/T',
  ew2Pool_pHCacTcInterfaceName: 'EW² Pool pH/CAC/T',
  ew2Pool_pHCleTcInterfaceName: 'EW² Pool pH/CLE/T',
  nova2Pool_pHOrpTcInterfaceName: 'Nova² Pool pH/ORP/T',
  nova2Pool_pHCacTcInterfaceName: 'Nova² Pool pH/CAC/T',
  nova2Pool_pHCleTcInterfaceName: 'Nova² Pool pH/CLE/T',
  alarm_minThresholdM1: 'E01 soglia minima M1',
  alarm_maxThresholdM1: 'E02 soglia massima M1',
  alarm_lvl1m1: 'E03 level 1 M1',
  alarm_lvl2m1: 'E04 level 2 M1',
  alarm_underRangeM1: 'E05 under range M1',
  alarm_overRangeM1: 'E06 over range M1',
  alarm_maxDosageM1: 'E07 massimo dosaggio M1',
  alarm_minThresholdM2: 'E09 soglia minima M2',
  alarm_maxThresholdM2: 'E10 soglia massima M2',
  alarm_lvl1m2: 'E11 level 1 M2',
  alarm_lvl2m2: 'E12 level 2 M2',
  alarm_underRangeM2: 'E13 under range M2',
  alarm_overRangeM2: 'E14 over range M2',
  alarm_maxDosageM2: 'E15 massimo dosaggio M2',
  alarm_sampleFlow: 'E18 flusso campione',
  alarm_pHPriority: 'E19 pH priority',
  output_pfmM1: 'DO1 - PFM M1',
  output_pfmM2: 'DO3 - PFM M2',
  output_outM1: 'DO8 - OUT M1',
  output_flocculant: 'DO7 - FLOCCULANTE',
  output_outM2: 'DO6 - OUT M2',
  output_antiAlgae: 'DO5 - ANTIALGA',
  output_poolRelayAlarm: 'DO9 - RELE’ ALLARME',
  input_lvl1m1: 'DI1 - Livello 1 M1',
  input_lvl2m1: 'DI2 - Livello 2 M1',
  input_externalConsent: 'DI3 - Consenso esterno',
  input_ecoMode: 'DI4 - Eco mode',
  input_sampleWater: 'DI6 - Samplewater',
  input_lvl1m2: 'DI8 - Livello 1 M2',
  input_lvl2m2: 'DI9 - Livello 2 M2',
  setpointM1OnOffParamDesc: 'Setpoint M1 On/Off',
  propBandM1OnOffParamDesc: 'Isteresi / Banda Proporzionale M1 On/Off',
  dosageDirM1OnOffParamDesc: 'Direzione Dosaggio M1 On/Off',
  maxDosageTimeM1OnOffParamDesc: 'Tempo MAX Dosaggio M1 On/Off',
  setpointM1PwmParamDesc: 'Setpoint M1 PWM',
  propBandM1PwmParamDesc: 'Isteresi / Banda Proporzionale M1 PWM',
  dosageDirM1PwmParamDesc: 'Direzione Dosaggio M1 PWM',
  maxDosageTimeM1PwmParamDesc: 'Tempo MAX Dosaggio M1 PWM',
  setpointM2OnOffParamDesc: 'Setpoint M2 On/Off',
  setpointM2PwmParamDesc: 'Setpoint M2 PWM',
  setpointM1PfmParamDesc: 'Setpoint M1 PFM',
  propBandM1PfmParamDesc: 'Isteresi / Banda Proporzionale M1 PFM',
  dosageDirM1PfmParamDesc: 'Direzione Dosaggio M1 PFM',
  maxDosageTimeM1PfmParamDesc: 'Tempo MAX Dosaggio M1 PFM',
  minFreqM1ParamDesc: 'Frequenza minima M1',
  maxFreqM1ParamDesc: 'Frequenza massima M1',
  minLimitM1ParamDesc: 'Limite MIN M1',
  maxLimitM1ParamDesc: 'Limite MAX M1',
  propBandM2OnOffParamDesc: 'Isteresi / Banda Proporzionale M2 On/Off',
  dosageDirM2OnOffParamDesc: 'Direzione Dosaggio M2 On/Off',
  maxDosageTimeM2OnOffParamDesc: 'Tempo MAX Dosaggio M2 On/Off',
  propBandM2PwmParamDesc: 'Isteresi / Banda Proporzionale M2 PWM',
  dosageDirM2PwmParamDesc: 'Direzione Dosaggio M2 PWM',
  maxDosageTimeM2PwmParamDesc: 'Tempo MAX Dosaggio M2 PWM',
  setpointM2PfmParamDesc: 'Setpoint M2 PFM',
  propBandM2PfmParamDesc: 'Isteresi / Banda Proporzionale M2 PFM',
  dosageDirM2PfmParamDesc: 'Direzione Dosaggio M2 PFM',
  maxDosageTimeM2PfmParamDesc: 'Tempo MAX Dosaggio M2 PFM',
  minFreqM2ParamDesc: 'Frequenza minima M2',
  maxFreqM2ParamDesc: 'Frequenza massima M2',
  setpointEcoModeParamDesc: 'Setpoint Eco/Boost mode',
  udmTempParamDesc: 'UDM Temperature',
  maxLimitM2ParamDesc: 'Limite MAX M2',
  minLimitM2ParamDesc: 'Limite MIN M2',
};
