import React from 'react';
import { Box, Button, IconButton, LinearProgress, TextareaAutosize } from '@mui/material';
import { ls } from '../../i18n/translations';
import SteielTextInput from '../ui/SteielTextInput';
import SteielButton from '../ui/SteielButton';
import ErrorMessage from '../ErrorMessage';
import { Form, Formik } from 'formik';
import {
  PASSWORD_MIN_LENGTH,
  validateEmail,
  validatePassword,
  validateRequiredField,
} from '../../helpers/validators';
import {
  capitalize,
  catchErrors,
  getLanguageCode,
  getLanguageFromCode,
  isObjEmpty,
  log,
  logErr,
  uploadLogo,
} from '../../helpers/utils';
import { Link, useNavigate } from 'react-router-dom';
import { Body1, Body2 } from '../ui/Typography';
import { useMQ } from '../../hook/useMQ';
import { colors } from '../../theme';
import Flex from '../ui/Flex';
import SteielSelect from '../ui/SteielSelect';
import { PageName } from '../../routes';
import { useAuth } from '../../hook/useAuth';
import { LanguageCode } from '../../graphql/codegen/graphql-react';
import SteielDialog from '../ui/SteielDialog';
import { useSetState } from 'react-use';
import { Close as CloseIcon } from '@mui/icons-material';
import { useDarkMode } from '../../hook/useDarkMode';

interface FormData {
  name: string;
  surname: string;
  email: string;
  role: string;
  notes: string;
  language: string;
}

const UserProfileForm: React.FC = () => {
  const auth = useAuth();
  const [errorFromServer, setErrorFromServer] = React.useState('');
  const [showDialog, setShowDialog] = React.useState(false);
  const { isDarkMode } = useDarkMode();
  const { isMobileOrTablet } = useMQ();
  const initialLanguageCodeRef = React.useRef(auth.user?.languageCode || LanguageCode.En);
  const needPageRefreshRef = React.useRef(false);
  const [state, setState] = useSetState<{
    logoFile: File | null;
    logoInputKey: number;
  }>({
    logoFile: null,
    logoInputKey: Date.now(),
  });

  const initialFormData: FormData = {
    name: auth.user?.name || '',
    notes: auth.user?.notes || '',
    role: capitalize(
      // @ts-ignore
      ls[
        // @ts-ignore
        (auth.user?.roles?.[0] || '').toLowerCase()
      ] || ls.viewer,
    ),
    surname: auth.user?.surname || '',
    email: auth.user?.email || '',
    language: getLanguageFromCode(auth.user?.languageCode || LanguageCode.En),
  };

  return (
    <Box>
      <Formik
        initialValues={initialFormData}
        validate={(values) => {
          const errors: Partial<FormData> = {};
          const name = validateRequiredField(values.name);
          if (!name.isValid) {
            errors.name = ls.getString(name.errorTranslationKey);
          }
          // reset errorFromServer
          if (!isObjEmpty(errors)) {
            setErrorFromServer('');
          }
          return errors;
        }}
        onSubmit={async (values, actions) => {
          try {
            log('submitting', values);
            setErrorFromServer('');
            const languageCode = getLanguageCode(values.language);
            const res = await auth.updateProfile({
              languageCode,
              name: values.name,
              notes: values.notes,
              surname: values.surname,
            });
            if (res?.data?.updateProfile?.id !== auth.user?.id) {
              throw new Error(ls.sww);
            }
            // upload logo
            if (state.logoFile && auth.user?.companyId) {
              const res = await uploadLogo(auth.user?.companyId, state.logoFile);
              if (!res.result) {
                setErrorFromServer(res.err || 'upload logo failed');
                return;
              }
            }
            if (languageCode !== initialLanguageCodeRef.current) {
              // lang changed
              initialLanguageCodeRef.current = languageCode;
              needPageRefreshRef.current = true;
              ls.setLanguage(languageCode);
            }
            setState({ logoFile: null, logoInputKey: Date.now() });
            setShowDialog(true);
          } catch (error) {
            logErr('error on submit profile user form', error);
            catchErrors(error, setErrorFromServer);
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {(props) => {
          const nameError = (props.dirty && props.touched.name && props.errors.name) || '';
          const firstError = nameError || errorFromServer || '';

          const labelWidth = isMobileOrTablet ? undefined : 180;
          const textFieldWidth = isMobileOrTablet ? '100%' : 500;
          const rootBoxWidth = isMobileOrTablet
            ? '100%'
            : // @ts-ignore
              labelWidth + textFieldWidth;

          return (
            <Form>
              <Box width={rootBoxWidth}>
                <Flex justifyContent='flex-start'>
                  <SteielTextInput
                    fieldName='name'
                    formProps={props}
                    label={capitalize(ls.name)}
                    labelStyle={{
                      weight: 600,
                      width: labelWidth,
                    }}
                    textFieldProps={{
                      placeholder: capitalize(ls.name),
                      required: true,
                      error: Boolean(nameError),
                      size: 'small',
                      style: {
                        backgroundColor: isDarkMode ? colors.darkBackground2 : '#fff',
                        width: textFieldWidth,
                      },
                    }}
                  />
                </Flex>
                <Flex mt={1} justifyContent='flex-start'>
                  <SteielTextInput
                    fieldName='surname'
                    formProps={props}
                    label={capitalize(ls.surname)}
                    labelStyle={{
                      weight: 600,
                      width: labelWidth,
                    }}
                    textFieldProps={{
                      placeholder: capitalize(ls.surname),
                      required: false,
                      error: false,
                      size: 'small',
                      style: {
                        backgroundColor: isDarkMode ? colors.darkBackground2 : '#fff',
                        width: textFieldWidth,
                      },
                    }}
                  />
                </Flex>
                <Flex mt={1} justifyContent='flex-start'>
                  <SteielTextInput
                    fieldName='email'
                    formProps={props}
                    label={capitalize(ls.email)}
                    labelStyle={{
                      weight: 600,
                      width: labelWidth,
                    }}
                    disabled={true}
                    textFieldProps={{
                      placeholder: capitalize(ls.email),
                      required: true,
                      type: 'email',
                      error: false,
                      size: 'small',
                      style: {
                        backgroundColor: isDarkMode ? colors.darkBackground2 : '#fff',
                        width: textFieldWidth,
                      },
                    }}
                  />
                </Flex>
                <Flex mt={1} justifyContent='flex-start'>
                  <SteielTextInput
                    fieldName='role'
                    formProps={props}
                    eyeIconColor={colors.lightGrey}
                    label={capitalize(ls.type)}
                    labelStyle={{
                      weight: 600,
                      width: labelWidth,
                    }}
                    disabled={true}
                    textFieldProps={{
                      placeholder: capitalize(ls.type),
                      required: true,
                      error: false,
                      size: 'small',
                      style: {
                        backgroundColor: isDarkMode ? colors.darkBackground2 : '#fff',
                        width: textFieldWidth,
                      },
                    }}
                  />
                </Flex>
                <Flex
                  mt={1}
                  justifyContent='flex-start'
                  flexDirection={isMobileOrTablet ? 'column' : 'row'}
                  alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                >
                  <Box>
                    <Body2 weight={600} width={labelWidth || 0}>{`${capitalize(
                      ls.language,
                    )}:`}</Body2>
                  </Box>
                  <Box width={isMobileOrTablet ? '100%' : 250} mt={isMobileOrTablet ? 0.5 : 0}>
                    <SteielSelect
                      allowedValues={[
                        capitalize(ls.italian),
                        capitalize(ls.english),
                        capitalize(ls.french),
                        capitalize(ls.spanish),
                      ]}
                      onChange={(value) => {
                        props.setFieldValue('language', value);
                      }}
                      initialVal={props.values.language}
                    />
                  </Box>
                </Flex>
                {(auth.isAdmin || auth.isSuperTechnician) &&
                (props.values.role.toLowerCase() === ls.superTechnician.toLowerCase() ||
                  props.values.role.toLowerCase() === ls.admin.toLowerCase()) ? (
                  <Flex
                    mt={1}
                    justifyContent='flex-start'
                    flexDirection={isMobileOrTablet ? 'column' : 'row'}
                    alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                  >
                    <Box>
                      <Body2
                        weight={600}
                        width={isMobileOrTablet ? '100%' : labelWidth || 0}
                      >{`${capitalize(ls.insertLogo)}:`}</Body2>
                    </Box>
                    <Flex>
                      <Box maxWidth={150} mt={isMobileOrTablet ? 0.5 : 0}>
                        <Button
                          variant='text'
                          component='label'
                          fullWidth
                          size='small'
                          style={{
                            backgroundColor: colors.blue,
                            color: 'white',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            borderColor: isDarkMode ? colors.darkBackground2 : undefined,
                            borderRadius: 4,
                            paddingTop: 3,
                            paddingBottom: 3,
                            paddingLeft: 20,
                            paddingRight: 20,
                          }}
                        >
                          <Body1 style={{ color: '#fff' }} weight={400}>
                            {capitalize(ls.upload)}
                          </Body1>
                          <input
                            key={state.logoInputKey}
                            hidden
                            accept='image/png'
                            multiple
                            type='file'
                            onChange={(ev) => {
                              const file = ev.target?.files?.[0];
                              if (!file) {
                                setState({ logoFile: null });
                                return;
                              }
                              if (file.size > 1024 * 1024 * 5) {
                                setErrorFromServer(ls.invalidFileSize);
                                setState({ logoFile: null });
                                return;
                              }
                              setState({ logoFile: file });
                            }}
                          />
                        </Button>
                      </Box>
                      {state.logoFile ? (
                        <IconButton
                          onClick={() => {
                            setState({ logoFile: null, logoInputKey: Date.now() });
                          }}
                          size='small'
                        >
                          <CloseIcon fontSize='small' />
                        </IconButton>
                      ) : null}
                    </Flex>
                    {state.logoFile ? (
                      <Box>
                        <Body2
                          weight={400}
                          width={isMobileOrTablet ? '100%' : labelWidth || 0}
                          ml={isMobileOrTablet ? 0 : 1}
                        >
                          {state.logoFile.name}
                        </Body2>
                      </Box>
                    ) : null}
                  </Flex>
                ) : null}
                <Flex
                  mt={1}
                  justifyContent='flex-start'
                  flexDirection={isMobileOrTablet ? 'column' : 'row'}
                  alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                >
                  <Box alignSelf='flex-start'>
                    <Body2
                      weight={600}
                      mt={isMobileOrTablet ? 0 : 1}
                      width={labelWidth || 0}
                    >{`${capitalize(ls.notes)}:`}</Body2>
                  </Box>
                  <Box width={isMobileOrTablet ? '100%' : undefined}>
                    <Box width={isMobileOrTablet ? '100%' : textFieldWidth}>
                      <TextareaAutosize
                        minRows={3}
                        maxRows={10}
                        placeholder={ls.insertNote}
                        style={{
                          width: '100%',
                          fontFamily: 'Roboto Condensed',
                          fontWeight: 300,
                          fontSize: '1.1rem',
                          padding: 12,
                          color: isDarkMode ? colors.lightGrey : colors.blue,
                          backgroundColor: isDarkMode ? colors.darkBackground2 : undefined,
                          borderColor: isDarkMode ? '#5a5c6f' : '#bdbdbd',
                        }}
                        value={props.values.notes}
                        onChange={(e) => {
                          props.setFieldValue('notes', e.target.value);
                        }}
                      />
                    </Box>
                  </Box>
                </Flex>
                <Box mt={1}>
                  <Link to={PageName.changePassword} style={{ textDecoration: 'none' }}>
                    <Body2 color={colors.lightBlue} weight={600}>
                      {capitalize(ls.changePassword)}
                    </Body2>
                  </Link>
                </Box>
              </Box>
              {firstError ? (
                <ErrorMessage
                  containerStyle={{
                    my: 2,
                  }}
                  message={firstError}
                />
              ) : (
                <Box />
              )}
              {props.isSubmitting && (
                <Box my={2}>
                  <LinearProgress />
                </Box>
              )}
              <Flex
                mt={3}
                mb={2}
                width={
                  isMobileOrTablet
                    ? undefined
                    : // @ts-ignore
                      textFieldWidth + labelWidth
                }
              >
                <Box flex={1} />
                <Box width={150}>
                  <SteielButton
                    text={ls.apply}
                    type='submit'
                    disabled={props.isSubmitting}
                    fullWidth
                    style={{
                      backgroundColor: colors.green,
                      borderColor: colors.green,
                    }}
                  />
                </Box>
              </Flex>
            </Form>
          );
        }}
      </Formik>
      <SteielDialog
        title={capitalize(ls.changesCompleted, false)}
        content={capitalize(ls.profileSavedSuccessfully)}
        onClose={() => {
          setShowDialog(false);
          if (needPageRefreshRef.current) {
            needPageRefreshRef.current = false;
            window.location.reload();
          }
        }}
        onOkActionClick={() => {
          setShowDialog(false);
          if (needPageRefreshRef.current) {
            needPageRefreshRef.current = false;
            window.location.reload();
          }
        }}
        open={showDialog}
        okLabel={capitalize(ls.ok)}
      />
    </Box>
  );
};

export default UserProfileForm;
